exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-healthtech-js": () => import("./../../../src/pages/healthtech.js" /* webpackChunkName: "component---src-pages-healthtech-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sector-biotech-js": () => import("./../../../src/pages/sector/biotech.js" /* webpackChunkName: "component---src-pages-sector-biotech-js" */),
  "component---src-pages-sector-fintech-js": () => import("./../../../src/pages/sector/fintech.js" /* webpackChunkName: "component---src-pages-sector-fintech-js" */),
  "component---src-pages-sector-healthtech-js": () => import("./../../../src/pages/sector/healthtech.js" /* webpackChunkName: "component---src-pages-sector-healthtech-js" */),
  "component---src-pages-sector-power-management-js": () => import("./../../../src/pages/sector/power-management.js" /* webpackChunkName: "component---src-pages-sector-power-management-js" */),
  "component---src-pages-sectors-js": () => import("./../../../src/pages/sectors.js" /* webpackChunkName: "component---src-pages-sectors-js" */),
  "component---src-pages-stay-updated-js": () => import("./../../../src/pages/stay-updated.js" /* webpackChunkName: "component---src-pages-stay-updated-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-what-we-build-js": () => import("./../../../src/pages/what-we-build.js" /* webpackChunkName: "component---src-pages-what-we-build-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

